import React, {FC, memo} from 'react';
import {IHomeNews} from '@redux/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import {getCardClassNames} from '@common/functions';
import NewsCard from '@modules/News/components/NewsCard';


const NewsSlider: FC<Omit<IHomeNews, 'step' | 'index'>> = ({ news }) => {
  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={20}
      className="main-news__swiper"
      wrapperClass="main-news__list"
      wrapperTag="ul"
    >
      {news.map((n, index) => {
        const classNames = [
          'news-card__picture',
          ...getCardClassNames(n.cardType || null, 'news-card__picture')
        ]
        const withLink = n.case || n.url
        return (
          <SwiperSlide
            key={`news_slide_${n.title}_${index}`}
            tag="li"
            aria-label="1 / 3"
            role="group"
            className="main-news__item"
          >
            <NewsCard item={n} classNames={classNames} withLink={withLink} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default memo(NewsSlider)
