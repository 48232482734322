import React, {FC} from 'react';
import {EBlockPosition, IHomeService} from '@redux/types';
import Step from '@components/component/Step';
import VacancyList from '@components/component/VacancyList';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import {THomeCount} from '@modules/Home/home.types';
import Link from 'next/link';
import Text from '@components/Text';


const HomeServices: FC<THomeCount<IHomeService>> = ({count, step, services}) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <Step
      step={step}
      count={count}
      classNames={['services']}
      childrenPosition={EBlockPosition.insideStepDescription}
      link={{
        title: t.services.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/services'
      }}
    >
      <ul className="services__list">
        {services?.map((service, index ) => (
          <li key={`service-item-${service?.slug}-${index}`} className="services__item">
            <Link
              className={`services__link ${service.meta?.onlyPreview ? 'only-preview' : ''}`}
              href={service?.slug && !service.meta?.onlyPreview ? `/services/${service.slug}` : ''}
            >
              <Text text={service.title}/>
            </Link>
          </li>
        ))}
      </ul>
    </Step>
  )
}

export default HomeServices
