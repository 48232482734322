import React, {FC, memo, useEffect, useState} from 'react';
import {THomeCount} from '@modules/Home/home.types';
import {IHomeSolution} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import {settings} from '@common/settings';
import Image from 'next/image';
import {initSliderScroll} from '@common/utils/sliderScroll';
import Link from 'next/link';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import Text from '@components/Text';

const HomeSolutions: FC<THomeCount<{slider: IHomeSolution}>> = ({ count, slider}) => {
  const t = useTranslation('components') as ITranslation['components']
  const [slideIndex, setSlideIndex] = useState(0)
  const [solutionUrl, setSolutionUrl] = useState('')

  useEffect(() => {
    initSliderScroll(setSlideIndex)
  }, [])

  useEffect(() => {
    const url = slider?.solutions[slideIndex]?.slug ?? null
    setSolutionUrl(url ? `/solutions/${url}` : '#')
  }, [slideIndex])

  return (
    <section className="slider-block">
      <div className="container">
        <div className="slider-block__wrapper" data-slider="scroll">
          <div className="slider-block__inner">
            <div className="section-heading"><span className="section-heading__count">{`0${count}`}</span>
              <h2 className="section-heading__title">
                <Text text={slider.step?.name}/>
              </h2>
            </div>
            <div className="slider-scroll">
              <div className="slider-scroll__wrapper">
                <h2 className="slider-scroll__title">
                  <Text text={slider.title}/>
                </h2>

                <div className="slider-scroll__slider-wrapper">
                  {slider.solutions.map((slide, index) => (
                    <div className="slider-scroll__slide" data-slider="slide-scroll" key={`slide_lock_${index}`}>
                      <div className="slider-scroll__title-block">
                        {slide.slide.title.map((t, index) => {
                          const image = t.icon.data?.attributes
                          const src = image?.url ?? null
                          return (
                            <div className="slider-scroll__title-item" key={`slide-scroll-item-${index}`}>
                              {(src && t.iconPosition === 'left') && (
                                <Image src={src} alt={image?.caption ?? 'image'} width={25} height={25} />
                              )}
                              <span>{t.text}</span>
                              {(src && t.iconPosition !== 'left') && (
                                <Image src={src} alt={image?.caption ?? 'image'} width={25} height={25} />
                              )}
                            </div>
                          )
                        })}
                      </div>

                      {slide.slide.text && (
                        <div className="slider-scroll__text">
                          <RichTextRender content={slide.slide.text} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <Link href={solutionUrl} className="btn hero__btn">
                  {t.slider.buttonText}
                </Link>

                <div className="slider-scroll__pagination" data-slider="pagination">
                  {slider.solutions.map((s, index) => (
                    <button
                      key={`slide_pagination_button_${index}`}
                      className={`slider-scroll__pagination-btn`}
                      type="button"
                      data-slider="pagination-btn"
                    />
                  ))}
                </div>

                <div className="slider-scroll__pagination-fractions">
                  <span className="slider-scroll__fraction-current" data-slider="fraction-current">1</span>
                  <span className="slider-scroll__fraction">/</span>
                  <span className="slider-scroll__fractions-all" data-slider="fractions-all">{slider.solutions.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(HomeSolutions)
