import React, {FC, memo} from 'react';
import Step from '@components/component/Step';
import {EBlockPosition, IHomePartner} from '@redux/types';
import Image from 'next/image';
import {settings} from '@common/settings';
import {THomeCount} from '@modules/Home/home.types';

const HomePartners: FC<THomeCount<IHomePartner>> = ({ count, step, partners }) => {
  return (
    <Step
      step={step}
      count={count}
      withoutMainClassname={true}
      childrenPosition={EBlockPosition.insideStepDescription}
      classNames={['cooperate', 'inner-grid--aside-left']}
    >
      <div className="cooperate__inner">
        {partners?.map((p, index) => (
          <ul className="cooperate__list" key={`coop_item_${p.name}_${index}`}>
            <li className="cooperate__item">
              <p className="cooperate__text cooperate__text--title">{p.name}</p>
            </li>
            {p.group.map((g, index) => {
              const logo = g.logo?.formats?.thumbnail ?? g.logo?.formats?.small ?? g.logo
              return (
                <li className="cooperate__item" key={`coop_group_item_${g.name}_${index}`}>
                  <p className="cooperate__text">{g.name}</p>
                  {logo?.url && (
                    <Image
                      className="cooperate__icon"
                      src={logo.url}
                      alt=""
                      width={logo?.width ?? 33}
                      height={logo?.height ?? 33}
                    />
                  )}
                </li>
              )
            })}
          </ul>
        ))}
      </div>
    </Step>
  )
}

export default memo(HomePartners)
