import React, {FC, memo} from 'react';
import Step from '@components/component/Step';
import {EBlockPosition, IHomeNews} from '@redux/types';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import {THomeCount} from '@modules/Home/home.types';
import NewsSlider from '@modules/News/components/NewsSlider';


const HomeNews: FC<THomeCount<IHomeNews>> = ({ count, step, news }) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <Step
      step={step}
      count={count}
      classNames={['main-news']}
      withoutMainClassname={true}
      childrenPosition={EBlockPosition.insideStep}
      link={{
        title: t.news.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/news'
      }}
    >
      <NewsSlider news={news}/>
    </Step>
  )
}

export default memo(HomeNews)
