import React, {FC, memo} from 'react';
import {EBlockPosition, IHomeCase} from '@redux/types';
import {THomeCount} from '@modules/Home/home.types';
import Step from '@components/component/Step';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import ProjectGrid from '@components/component/Projects/ProjectGrid';

const HomeCases: FC<THomeCount<IHomeCase>> = ({ count, step, projects }) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <Step
      id="main-projects"
      step={step}
      count={count}
      classNames={['main-project']}
      withoutMainClassname={true}
      childrenPosition={EBlockPosition.insideStep}
      link={{
        title: t.project.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/projects'
      }}
    >
      <ProjectGrid projects={projects || []} isHomePageGrid={true} />
    </Step>
  )
}

export default memo(HomeCases)
