import React, {FC} from 'react';
import Step from '@components/component/Step';
import {THomeCount} from '@modules/Home/home.types';
import {EBlockPosition, IHomeReview} from '@redux/types';
import {useTranslation} from '@common/hooks/useTranslation';
import {IReviewsItem, ITranslation} from '@common/types';
import ReviewsGrid from '@components/ReviewsGrid';

const HomeReviews: FC<THomeCount<IHomeReview>> = ({ count, step, reviews }) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <Step
      step={step}
      count={count}
      classNames={['main-review']}
      withoutMainClassname={true}
      childrenPosition={EBlockPosition.insideStep}
      link={{
        title: t.review.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/feedback'
      }}
    >
      <ReviewsGrid
        type="reviews"
        reviews={reviews?.map((r) => ({
          title: r.description,
          description: r.companyName,
          customer: r.customer,
          color: r.color,
          cardType: r.cardType,
          preview: r?.preview
        })) as IReviewsItem[] || []}
      />
    </Step>
  )
}

export default HomeReviews
