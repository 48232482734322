import React, {FC, memo} from 'react';
import Link from 'next/link';
import {EPage, IReviewsItem, IReviewsProps, ITranslation} from '@common/types';
import Icon from '@components/Icon';
import Picture from '@components/component/Picture';
import {EComponent} from '@redux/types';
import {useActions} from '@common/hooks/useActions';
import Text from '@components/Text';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useTranslation} from '@common/hooks/useTranslation';

const ReviewsGrid: FC<IReviewsProps> = ({reviews, type}) => {
  const actions = useActions()
  const {page} = useTypedSelector(state => state.app)
  const t = useTranslation('components') as ITranslation['components']

  const ReviewItem: FC<IReviewsItem> = ({title, description, customer, preview}) => (
    <>
      {!preview ? (
        <div className={page !== EPage.SOLUTION ? "review__text" : "review__text review__text-big-title"}>
          <p>
            <Text text={title}/>
          </p>
        </div>
      ) : (
        <>
          <div className="review__picture">
            <Picture media={{__component: EComponent.MEDIA, image: preview.image}} withoutWrapper={true}/>
          </div>
          <button
            className="review__play"
            onClick={() => actions.setApp({modalVideo: {video: preview?.video, open: true}})}
            data-review-video-link="v-3/video/video-review-geoscan.mp4"
          >
            <span className="visually-hidden">{t.video.visuallyHidden}</span>
            <Icon name="icon-play" className="review__play-icon" width={50} height={58}/>
          </button>
        </>
      )}
      {description && (
        <div className="review__author">
          {customer && (
            <span className="review__name">{customer}</span>
          )}
          <span className="review__project"><Text text={description}/></span>
        </div>
      )}
    </>
  )

  return (
    <div className={`review ${type === 'services' ? 'review__links' : ''}`}>
      <ul className="review__list">
        {reviews?.map((item, index) => {
          let classNames = ['review__item', `review__item--bg-${item?.color ?? 'gray'}`]
          if (!item.cardType) {
            classNames.push('review__item--type-big')
          }
          if (item.preview) {
            classNames.push('review__item--type-video')
          }
          if (item.cardType === 'rounded-right') {
            classNames.push('review__item--type-big review__item--radius-right')
          }
          if (item.cardType === 'rounded') {
            classNames.push('review__item--type-big review__item--center review__item--radius-round')
          }
          if (item.cardType === 'circle') {
            classNames.push('review__item--center review__item--radius-round')
          }
          if (type === 'services') {
            classNames.push('review__item--text-big')
          }
          return (
            <li className={classNames.join(' ')} key={`review_${item.title}_${index}`}>
              {type === 'services' && !item?.onlyPreview ? (
                <Link className="review__link" href={`/services/${item?.slug}`}>
                  <ReviewItem title={item.title} description={item.description}/>
                </Link>
              ) : (
                <ReviewItem
                  title={item.title}
                  description={item.description}
                  customer={item.customer || ''}
                  preview={item.preview || null}
                />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}


export default memo(ReviewsGrid)
