import React, {FC, memo} from 'react';
import Picture from '@components/component/Picture';
import {EComponent} from '@redux/types';
import Link from 'next/link';
import {getDomainFromUrl} from '@common/functions';
import {INewsCardProps, ITranslation} from '@common/types';
import {useTranslation} from '@common/hooks/useTranslation';
import DOMPurify from 'isomorphic-dompurify';

const NewsCard: FC<INewsCardProps> = ({ item, classNames, withLink }) => {
  const t = useTranslation('news') as ITranslation['news']

  return (
    <div className="news__item">
      <div className="news-card">
        <div className={classNames.join(' ')}>
          {item.image && (
            <Picture
              media={{__component: EComponent.MEDIA, image: item.image, imageMobile: item.imageMobile}}
              withoutWrapper={true}
            />
          )}
        </div>
        <div className="news-card__text">
          <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title ?? '')}} />
        </div>

        {withLink ? (
          <Link
            className="news-card__link"
            href={item.case ? `/projects/${item.case}` : item.url ?? '#'}
            target={item.case ? '_self' : '_blank'}
          >
            {item.urlTitle ? item.urlTitle : item.case ? t.card.case : getDomainFromUrl(item.url!!)}
          </Link>
        ) : (
          <a className="news-card__link" href="">
            {t.card.soon}
          </a>
        )}
      </div>
    </div>
  )
}

export default memo(NewsCard)
